import React from "react"
import { css } from "@emotion/core"
import rs1 from "../../images/dm.png"
import rs2 from "../../images/space.png"
import rs3 from "../../images/coffee_offfice.png"
import { containerWidth } from "../../constants/css.json"
import useIntersection from "../../hook/useIntersection"
import { langTable } from "../../constants/language"

type RsCardProps = {
  title: string
  subTitle: string
  content: string
  src: string
}

function RSCard({ title, subTitle, content, src }: RsCardProps) {
  // 줄 바꿈을 <br> 태그로 변환
  const formattedContent = content.split("\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ))

  return (
    <div css={reason__card}>
      <img src={src} alt={title} />
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <p>{formattedContent}</p>
    </div>
  )
}

function Reason({ langIndex }: { langIndex: number }) {
  const cards = [
    {
      id: 1,
      src: rs1,
      title: "직영 운영&관리",
      subTitle: "추가 채용 NO",
      content:
        "기기관리 전문가가 주기적으로 방문하여\n재고 관리와 기기 점검을 책임집니다.",
    },
    {
      id: 2,
      src: rs2,
      title: "공간 디자인",
      subTitle: "휴게실을 카페로",
      content:
        "최적의 비용으로 최상의 휴식공간을 만들어주는\n플랜즈만의 인테리어 디자인을 제공합니다.",
    },
    {
      id: 3,
      src: rs3,
      title: "차별화된 퀄리티",
      subTitle: "사무실에서 맛보는 유명 스폐셜티 커피",
      content:
        "플랜즈와 함께하는 유명 로스터리의\n스폐셜티 커피를 사무실에서 즐겨보세요.",
    },
  ]
  return (
    <div css={reason__wrap}>
      <h1>
        {langTable[langIndex].reason__title__upper} <br />
        {langTable[langIndex].reason__title__lower}
      </h1>
      <h2>
        {langTable[langIndex].reason__subTitle__upper}
        <br />
        {langTable[langIndex].reason__subTitle__lower}
      </h2>
      <div css={reason__cards}>
        {cards.map(({ id, title, content, subTitle, src }, index) => (
          <RSCard
            key={id}
            title={title}
            subTitle={subTitle}
            content={content}
            src={src}
            {...useIntersection({ delay: index * 0.1 })}
          />
        ))}
      </div>
    </div>
  )
}

const reason__wrap = css`
  @media (max-width: ${containerWidth}) {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  padding-top: 69px;
  padding-bottom: 51px;
  box-sizing: border-box;
  width: 100%;
  background-color: #f7f6f4;
  font-family: Spoqa Han Sans Neo;
  h1 {
    @media (max-width: ${containerWidth}) {
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
    }
    margin-bottom: 37px;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
  h2 {
    @media (max-width: ${containerWidth}) {
      font-size: 0.8rem;
      margin-bottom: 1.6rem;
      color: ;
    }
    margin-bottom: 47px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #414141;
  }
`

const reason__cards = css`
  @media (max-width: ${containerWidth}) {
    justify-content: space-around;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`

const reason__card = css`
  @media (max-width: ${containerWidth}) {
    margin-right: 0;
    width: 400px;
    height: 430px;
  }
  @media (max-width: 500px) {
    width: 80%;
    height: 320px;
    display: flex;
    gap: 10px;
  }
  width: 400px;
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 45px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  box-sizing: border-box;
  font-family: Spoqa Han Sans Neo;
  img {
    @media (max-width: ${containerWidth}) {
      margin-top: 41px;
      width: 214px;
    }
    @media (max-width: 500px) {
      width: 50%;
      margin-top: 10px;
    }
    width: 180px;
    margin-top: 23px;
  }
  h1 {
    @media (max-width: ${containerWidth}) {
      font-size: 1.2rem;
      margin-top: 0;
    }
    @media (max-width: 500px) {
      font-size: 1.2rem;
      margin-top: 0;
    }
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #414141;
  }
  h2 {
    @media (max-width: ${containerWidth}) {
      font-size: 0.9rem;
    }
    @media (max-width: 500px) {
      font-size: 0.9rem;
      margin-top: 0;
      margin-bottom: 0;
    }
    margin-top: 8px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #414141;
  }
  p {
    @media (max-width: ${containerWidth}) {
      font-size: 0.8rem;
      margin-bottom: 0;
    }
    width: 80%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #8d8989;
  }
`

export default Reason
