// 외부 모듈
import React, { useEffect, useRef } from "react"
import { css, keyframes } from "@emotion/core"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import ModalBackground from "./modalBackground"

// 내부모듈
import { langTable } from "../../constants/language"

interface ContactModalProps {
  closeModal: () => void
  langIndex: number
}

function ContactModal({ closeModal, langIndex }: ContactModalProps) {
  const firstInput = useRef(null)

  useEffect(() => {
    // @ts-ignore

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "PageView")
        window.fbq("track", "ViewContent", {
          content_ids: "R000",
          content_name: "상담예약페이지",
        })
      }
    }
  }, [])

  return (
    <ModalBackground closeModal={closeModal}>
      <section css={modalBoxStyle}>
        <h1 css={titleStyle}>{langTable[langIndex].contact__modal__title}</h1>
        <p css={subTitleStyle}>
          {langTable[langIndex].contact__modal__subTitle}
        </p>
        <form
          method="post"
          action="https://formspree.io/moqklgpg"
          css={formStyle}
        >
          <div>
            <label htmlFor="companyName" css={labelStyle}>
              {langTable[langIndex].contact__modal__company__label}
            </label>
            <input
              id="companyName"
              name="기업명/담당자성함"
              css={narrowInputStyle}
              placeholder={
                langTable[langIndex].contact__modal__company__placeholder
              }
              ref={firstInput}
            ></input>
          </div>
          {/* <div>
            <label htmlFor="managerName" css={labelStyle}>
              담당자 이름
            </label>
            <input
              id="managerName"
              name="담당자"
              css={narrowInputStyle}
              placeholder="담당자 이름을 입력해주세요"
            ></input>
          </div> */}
          <div>
            <label htmlFor="phoneNumber" css={labelStyle}>
              {langTable[langIndex].contact__modal__phone__label}
            </label>
            <input
              id="phoneNumber"
              name="휴대전화번호"
              css={narrowInputStyle}
              placeholder={
                langTable[langIndex].contact__modal__phone__placeholder
              }
              type="tel"
            ></input>
          </div>
          <div>
            <label htmlFor="email" css={labelStyle}>
              {langTable[langIndex].contact__modal__email__label}
            </label>
            <input
              id="email"
              name="이메일"
              css={narrowInputStyle}
              placeholder={
                langTable[langIndex].contact__modal__email__placeholder
              }
              type="email"
            ></input>
          </div>
          {/* <div>
            <label htmlFor="detail" css={labelStyle}>
              기타문의
            </label>
            <textarea
              id="detail"
              name="기타문의"
              css={wideInputStyle}
              placeholder="문의 내용을 입력해주세요"
            ></textarea>
          </div> */}
          <div css={buttonBoxStyle}>
            <button css={buttonStyle} onClick={closeModal}>
              {langTable[langIndex].contact__modal__cancel}
            </button>
            <button
              css={[buttonStyle, primaryColor]}
              type="submit"
              onClick={() => {
                if (typeof window !== "undefined") {
                  if (window.fbq != null) {
                    window.fbq("track", "CompleteRegistration", {
                      content_name: "상담예약완료",
                    })
                  }
                }
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "Submit Button",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "PLANZ FIRST LANDING PAGE",
                })
              }}
            >
              {langTable[langIndex].contact__modal__request}
            </button>
          </div>
        </form>
      </section>

      <script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            if (!wcs_add) var wcs_add={};
wcs_add["wa"] = "s_34e676a09cf2";
if (!_nasa) var _nasa={};
_nasa["cnv"] = wcs.cnv("4","1"); 
wcs_do(_nasa);
        `,
        }}
      />
    </ModalBackground>
  )
}

const raseAnimation = keyframes`
  from {
    transform: translate3d(0, 100vh, 0);
  } 
  to {
    transform: translate3d(0,0,0);
  }
`

const modalBoxStyle = css`
  @media (max-width: ${"480px"}) {
    width: 85vw;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  animation: ${raseAnimation} 0.5s ease;
  margin: 10vh auto;
  width: 840px;
  // height: 문의하기 삭제하면서 615px => 515px 수정
  height: 515px;
  border-radius: 30px;
  background-color: #fff;
  z-index: 30;

  font-family: Spoqa Han Sans Neo;
`

const titleStyle = css`
  @media (max-width: ${"480px"}) {
    font-size: 18px;
    margin-top: 0;
  }
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #414141;
  margin-top: 40px;
  margin-bottom: 22px;
`
const subTitleStyle = css`
  @media (max-width: ${"480px"}) {
    font-size: 11px;
    margin-top: 8px;
  }
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #414141;
  margin-bottom: 20px;
`

const formStyle = css`
  @media (max-width: ${"480px"}) {
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 85%;
    padding-left: 0px;
  }
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
`

const narrowInputStyle = css`
  @media (max-width: ${"480px"}) {
    width: calc(100% - 15px);
    height: 40px;
    font-size: 11px;
  }
  width: 340px;
  height: 53px;
  border-radius: 10px;
  border: none;
  margin: 0px 40px 15px 0;
  padding-left: 15px;
  background-color: #f4f4f4;
  font-size: 18px;
  font-weight: 300;
  outline-color: #d8934f;
`
const wideInputStyle = css`
  @media (max-width: ${"480px"}) {
    width: calc(100% - 15px);
    height: 72px;
    font-size: 11px;

    ::placeholder {
      font-size: 11px;
    }
  }
  width: 740px;
  height: 97px;
  margin: 0px 0 22px 0;
  border-radius: 10px;
  border: none;
  background-color: #f4f4f4;
  font-size: 18px;
  font-weight: 300;
  padding-left: 15px;
  outline-color: #d8934f;
  resize: none;
  padding-top: 13px;

  ::placeholder {
    font-size: 18px;
  }
`

const labelStyle = css`
  @media (max-width: ${"480px"}) {
    font-size: 11px;
  }
  display: block;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
`

const buttonStyle = css`
  @media (max-width: ${"480px"}) {
    width: 40%;
    height: 30px;
    // 기타문의 삭제하면서 margin: 0 => 20px 0 0 0
    margin: 20px 0 0 0;
    font-size: 13px;
  }
  width: 212px;
  height: 55px;
  // 기타문의 삭제하면서 margin-top 0 => 40px
  margin: 40px 40px 0 15px;
  border-radius: 20px;
  border: none;
  background-color: #d9d9d9;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  outline-color: #d8934f;
`

const buttonBoxStyle = css`
  @media (max-width: ${"480px"}) {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  margin: 0 auto;
  padding-right: 24px;
`

const primaryColor = css`
  background-color: #d8934f;
  outline-color: #333333;
  color: #fff;
`

export default ContactModal
