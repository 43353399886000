// external modules
import React, { useState } from "react"

// internal modules
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Introduction from "../components/home/introduction"
import VideoModal from "../components/home/videoModal"
import Partners from "../components/home/partners"
import Needs from "../components/home/needs"
import TryFreeTasting from "../components/home/tryFreeTasting"
import News from "../components/home/news"
import InterviewAndHotIssue from "../components/home/interviewAndHotIssue"
import Menu from "../components/home/menu"
import Cleaning from "../components/home/cleaning"
import Reason from "../components/home/reason"
import Pros from "../components/home/pros"
import Case from "../components/home/case"
import ContactModal from "../components/modal/contactModal"
import Product from "../components/home/product"

interface location {
  pathname: string
}

type HomeProps = {
  location: location
}

function Home({ location }: HomeProps) {
  const [isOpen, setModal] = useState(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [langIndex, setLangIndex] = useState(0)
  return (
    <Layout
      currentPath={location.pathname}
      setLangIndex={setLangIndex}
      langIndex={langIndex}
    >
      <SEO title="Home" />
      {isContactModalOpen && (
        <ContactModal
          closeModal={() => {
            setIsContactModalOpen(false)
          }}
          langIndex={langIndex}
        />
      )}
      <VideoModal
        isOpen={isOpen}
        closeModal={() => {
          setModal(false)
        }}
      />
      <Introduction
        openModal={() => {
          setIsContactModalOpen(true)
        }}
        langIndex={langIndex}
      />
      <Product />
      <Case langIndex={langIndex} />
      <Reason langIndex={langIndex} />
      {/* <Partners langIndex={langIndex} /> */}

      <Pros langIndex={langIndex} />
      <Needs langIndex={langIndex} />
      {/* <Interface />
      {/* <Process /> */}
      {/* <Roastery /> */}
      {/* <Management /> */}
      {/* <Clean />
      <Feedback />
      <Profit /> */}
      {/* <Introduction openModal={() => {}} /> 
      <Pros /> */}
      {/* <Reason /> */}
      <TryFreeTasting
        openModal={() => {
          setIsContactModalOpen(true)
        }}
        langIndex={langIndex}
      />
      <News langIndex={langIndex} />
      <InterviewAndHotIssue langIndex={langIndex} />
      <Menu langIndex={langIndex} />
      <Cleaning langIndex={langIndex} />
    </Layout>
  )
}

export default Home
