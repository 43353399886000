// external moduels
import React from "react"
import { css } from "@emotion/core"

// internal variables
const videoStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
`

const Video = () => (
  <div css={iframebox}>
    <iframe
      title="vimeoPlayer"
      src="https://player.vimeo.com/video/380418030?autoplay=1&background=1"
      allow="autoplay"
      frameBorder="0"
      style={{ display: "block" }}
      css={videoStyle}
    />
  </div>
)

// internal variables
const iframebox = css`
  position: fixed;
  top: 25%;
  width: 100%;
  height: 40%;
  /* border-radius: 20px;
  box-shadow: 0 20px 30px 0 rgba(156, 130, 108, 0.2); */
  /* margin-top: 40px;
  margin-bottom: 40px; */
  @media (max-width: 1088px) {
    width: 100vw;
  }
`
export default Video
