import React from "react"
import { css } from "@emotion/core"
import checked from "../../images/checked.png"
import { containerWidth } from "../../constants/css.json"
import useIntersection from "../../hook/useIntersection"
import { langTable } from "../../constants/language"

function Pros({ langIndex }: { langIndex: number }) {
  return (
    <div css={pros__wrap}>
      <h1>
        {langTable[langIndex].pros__title__upper}
        <br />
        {langTable[langIndex].pros__title__lower}
      </h1>
      <ul>
        <li {...useIntersection({ distance: "100px" })}>
          <img src={checked} />
          <p> {langTable[langIndex].pros__p1}</p>
        </li>
        <li {...useIntersection({ delay: 0.1, distance: "100px" })}>
          <img src={checked} />
          <p> {langTable[langIndex].pros__p2}</p>
        </li>
        <li {...useIntersection({ delay: 0.2, distance: "100px" })}>
          <img src={checked} />
          <p> {langTable[langIndex].pros__p3}</p>
        </li>
        <li {...useIntersection({ delay: 0.3, distance: "100px" })}>
          <img src={checked} />
          <p> {langTable[langIndex].pros__p4}</p>
        </li>
      </ul>
    </div>
  )
}

const pros__wrap = css`
  @media (max-width: ${containerWidth}) {
    width: 480px;
    height: 414px;
    /* padding-top: 60px;
    padding-bottom: 60px; */
  }
  @media (max-width: 500px) {
    height: 100%;
    width: 80%;
    margin-top: 24px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1044px;
  height: 452px;
  margin-top: 60px;
  /* margin-bottom: 60px; */
  border-radius: 45px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  font-family: Spoqa Han Sans Neo;
  h1 {
    @media (max-width: ${containerWidth}) {
      font-size: 30px;
    }
    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
  }
  ul {
    @media (max-width: ${containerWidth}) {
      margin-top: 2rem;
    }
    margin-top: 44px;
    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 21px;
      @media (max-width: ${containerWidth}) {
        margin-bottom: 1rem;
      }
      p {
        @media (max-width: ${containerWidth}) {
          height: 29px;
          line-height: 29px;
          font-size: 20px;
        }
        @media (max-width: 500px) {
          font-size: 0.8rem;
          height: 1.2rem;
          line-height: 1.4rem;
        }
        height: 29px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 29px;
        letter-spacing: normal;
        text-align: left;
        color: #414141;
      }

      img {
        @media (max-width: 500px) {
          width: 1.2rem;
          margin-right: 0.8rem;
        }
        width: 29px;
        margin-right: 18px;
      }
    }
  }
`

export default Pros
