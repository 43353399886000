// 외부 모듈
import React, { useEffect, useRef, useState } from "react"
import { css } from "@emotion/core"

// polyfill
import CategoryWithIcon from "./categoryWithIcon"

// image
import interviewIcon from "../../images/interviewIcon.png"
import interviewImg from "../../images/interviewImg.png"
import hotissueIcon from "../../images/hotissueIcon.png"
import hotissueImg from "../../images/summer_event.png"
import useIntersection from "../../hook/useIntersection"
import { langTable } from "../../constants/language"

// 컴포넌트 반응형 넓이
const MOBILE_WIDTH = "1200px"

const INTERVIEW_LINK = "https://www.youtube.com/watch?v=B3AiuRlonGk"
const SPACE_LINK =
  "https://www.sisunnews.co.kr/news/articleView.html?idxno=149315"

function InterView({ langIndex }: { langIndex: number }) {
  return (
    <div css={boxStyle}>
      <div css={topStyle} {...useIntersection()}>
        <CategoryWithIcon src={interviewIcon} category="인터뷰" />
        <p css={titleStyle}>
          {langTable[langIndex].interview__title__upper}
          <br />
          {langTable[langIndex].interview__title__lower}
        </p>
        <p css={contentsTextStyle}>
          {" "}
          {langTable[langIndex].interview__content}
        </p>
      </div>
      <div css={bottomStyle}>
        <iframe
          width="493"
          height="341"
          src="https://www.youtube.com/embed/B3AiuRlonGk"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        {/* <a href={INTERVIEW_LINK} target="_blank" css={linkStyle}>
          <img src={interviewImg} alt="인터뷰이미지" />
        </a> */}
      </div>
    </div>
  )
}

function HotIssue({ langIndex }: { langIndex: number }) {
  return (
    <div css={boxStyle}>
      <div css={topStyle} {...useIntersection()}>
        <CategoryWithIcon src={hotissueIcon} category="핫이슈" />
        <p css={titleStyle}>
          시원한 음료 마시고
          <br />
          여름 휴가 가자
        </p>
        <p css={contentsTextStyle}>여름맞이 프로모션 이벤트</p>
      </div>
      <div css={bottomStyle}>
        <a target="_blank" css={linkStyle}>
          <img src={hotissueImg} alt="핫이슈이미지" />
        </a>
      </div>
    </div>
  )
}

function InterviewAndHotIssue({ langIndex }: { langIndex: number }) {
  return (
    <section css={containerStyle}>
      <InterView langIndex={langIndex} />
      <HotIssue langIndex={langIndex} />
    </section>
  )
}

const containerStyle = css`
  @media all and (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
  display: flex;
  justify-content: space-between;
  width: 1044px;
  margin-top: 60px; // 임시
  font-family: Spoqa Han Sans Neo;
`

const boxStyle = css`
  width: 493px;
  height: 700px;
  border-radius: 50px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const topStyle = css`
  padding: 49px 77px 51px 50px;
`

const titleStyle = css`
  font-size: 40px;
  font-weight: 600;
  line-height: 1.5;
`
const contentsTextStyle = css`
  font-size: 25px;
  color: #454545;
  margin-top: 1.5rem;
`

const bottomStyle = css`
  overflow: hidden;
  border-radius: 0 0 50px 50px;
  img {
    /* width: 100%; */
    height: 100%;
    transform: translateX(-12%);
  }
`

const linkStyle = css`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

export default InterviewAndHotIssue
