// 외부모듈
import React from "react"
import { css } from "@emotion/core"

// images
import planzWhiteLogo from "../../images/planzWhiteLogo.png"
import { langTable } from "../../constants/language"

const LINK = "https://brunch.co.kr/@rladbtls003/26"

// 컴포넌트 반응형 넓이
const MOBILE_WIDTH = "1200px"

function Cleaning({ langIndex }: { langIndex: number }) {
  return (
    <section css={containerStyle}>
      <img src={planzWhiteLogo} alt="플랜즈로고" css={logoStyle} />
      <h1 css={titleStyle}>{langTable[langIndex].cleaning__title}</h1>
      <p css={contentsTextStyle}>
        {langTable[langIndex].cleaning__content__upper}
      </p>
      <p css={contentsTextStyle}>
        {langTable[langIndex].cleaning__content__lower}
      </p>
      <a href={LINK} target="_blank" css={readmoreStyle}>
        {langTable[langIndex].cleaning__link} &#62;
      </a>
    </section>
  )
}

const containerStyle = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
  width: 1044px;
  height: 560px;
  border-radius: 50px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(229deg, #059ed6 15%, #afeaff 88%);
  line-height: 1.5;
  text-align: center;
  color: #fff;
  margin-top: 60px;
  margin-bottom: 55px;
  font-family: Spoqa Han Sans Neo;
`
const logoStyle = css`
  width: 49.6px;
  height: 44.4px;
`

const titleStyle = css`
  font-size: 40px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 27px;
`
const contentsTextStyle = css`
  font-size: 24px;
  font-weight: normal;
`
const readmoreStyle = css`
  margin-top: 19px;
  font-size: 24px;
  color: #0669be;
`

export default Cleaning
