// 외부모듈
import React from "react"
import { css } from "@emotion/core"

interface CategoryWithIconProps {
  src: any
  category: string
}

function CategoryWithIcon({ src, category }: CategoryWithIconProps) {
  return (
    <section css={containerStyle}>
      <img css={iconStyle} src={src} alt="카테고리 아이콘" />
      <h1 css={textStyle}>{category}</h1>
    </section>
  )
}

const containerStyle = css`
  height: 73px;
  border-radius: 32px;
  background-color: #f4f4f4;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
  font-family: Spoqa Han Sans Neo;
`

const textStyle = css`
  font-size: 24px;
  font-weight: 600;
  margin-right: 29px;
`

const iconStyle = css`
  width: 40px;
  height: 40px;
  margin: 0 21px;
`

export default CategoryWithIcon
