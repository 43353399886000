import React, { useCallback, useEffect, useRef } from "react"
import "intersection-observer"
interface Options {
  direction?: "up" | "down" | "left" | "right"
  duration?: number
  delay?: number
  distance?: string
}
const DEFAULT_OPTIONS: Options = {
  direction: "up",
  duration: 1,
  delay: 0,
  distance: "50%",
}

function useIntersection(options: Options = DEFAULT_OPTIONS) {
  const {
    delay = 0,
    duration = 1,
    direction = "up",
    distance = "50%",
  } = options
  const element = useRef() as any

  const handleDirection = (name: string) => {
    switch (name) {
      case "up":
        return `translate3d(0, ${distance}, 0)`
      case "down":
        return `translate3d(0, -${distance}, 0)`
      case "left":
        return `translate3d(${distance}, 0, 0)`
      case "right":
        return `translate3d(-${distance}, 0, 0)`
      default:
        return
    }
  }

  const onScroll = useCallback(
    ([entry]) => {
      const { current }: { current: HTMLElement } = element
      if (entry.isIntersecting) {
        current.style.transitionProperty = "all"
        current.style.transitionDuration = `${duration}s`
        current.style.transitionTimingFunction = "cubic-bezier(0, 0, 0.2, 1)"
        current.style.transitionDelay = `${delay}s`
        current.style.opacity = "1"
        current.style.transform = "translate3d(0, 0, 0)"
      }
    },
    [delay, duration]
  )

  useEffect(() => {
    let observer: IntersectionObserver

    if (element.current) {
      observer = new IntersectionObserver(onScroll, { threshold: 0.3 })
      observer.observe(element.current)
    }

    return () => observer && observer.disconnect()
  }, [onScroll])

  return {
    ref: element,
    style: { opacity: 0, transform: handleDirection(direction) },
  }
}

export default useIntersection
