// external modules
import React from "react"
import { navigate } from "gatsby"
import { css, keyframes } from "@emotion/core"

// internal modules
import closeButton from "../../images/close_white.png"

// constants
import { containerWidth } from "../../../constants/css.json"
import Video from "./video"

type modalProps = {
  isOpen: Boolean
  closeModal: () => void
}

function VideoModal({ isOpen, closeModal }: modalProps) {
  const onClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault()
    closeModal()
  }
  if (!isOpen) {
    return null
  }
  return (
    <div css={container}>
      <img
        css={closeButtonStyle}
        onClick={onClick}
        src={closeButton}
        alt="closeButton"
      />

      <Video />
    </div>
  )
}

const container = css`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
`

const closeButtonStyle = css`
  @media (max-width: 1088px) {
    top: 80%;
    right: calc(50% - 14px);
    width: 28px;
    height: 28px;
  }
  position: absolute;
  top: 100px;
  right: 100px;
  width: 38px;
  height: 38px;
`

export default VideoModal
