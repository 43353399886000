// external modules
import React, { ReactNode } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

// interanl modules
import { css } from "@emotion/core"
import { langTable } from "../../constants/language"
import Section from "./section"
import background from "../../images/main.jpg"
import backgroundMobile from "../../images/main-mobile.jpg"
import { containerWidth } from "../../constants/css.json"
import useIntersection from "../../hook/useIntersection"

type IntroductionProps = {
  openModal: () => void
  langIndex: number
}
// Section 컴포넌트는 각 화면의 구획을 표현합니다.
function Introduction({ openModal, langIndex }: IntroductionProps) {
  return (
    <Section backgroundColor="#FFFFFF" width="100%">
      {/* <div css={frontground} /> */}
      <div css={backgroundImg}>
        <div css={innerContainer}>
          <h3 css={sub__title} {...useIntersection({ distance: "100px" })}>
            {langTable[langIndex].introduction__sub__title}
          </h3>
          <h1 css={title} {...useIntersection({ distance: "100px" })}>
            {langTable[langIndex].introduction__title__upper}
            <br />
            {langTable[langIndex].introduction__title__lower}
          </h1>
          <h2 css={welcome__title} {...useIntersection({ distance: "100px" })}>
            {langTable[langIndex].introduction__welcome__title}
          </h2>
          <div css={buttonsLayout}>
            <button
              css={buttonContact}
              onClick={() => {
                // navigate("/contact")
                openModal()
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "Contact Page From Home Button",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "PLANZ FIRST LANDING PAGE",
                })
              }}
            >
              {langTable[langIndex].introduction__button}
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

// Section 컴포넌트는 각 화면의 구획을 표현합니다.
function Introduction_({ openModal, langIndex }: IntroductionProps) {
  return (
    <Section backgroundColor="#FFFFFF" width="100%">
      {/* <div css={frontground} /> */}
      <div css={backgroundGrad}>
        <h1 css={title_} {...useIntersection({ distance: "100px" })}>
          사내카페 무료도입 ☕
          <br />
        </h1>
        <h2 css={welcome__title_} {...useIntersection({ distance: "100px" })}>
          <br />
          도입하기 어려운 사내 카페
          {/* <span style={{ fontWeight: 600 }}> 무인 카페 서비스</span> */}
          <br />
          플랜즈커피와 함께 무료로 도입하세요
        </h2>
        <div css={buttonsLayout_}>
          <button
            css={buttonContact_}
            onClick={() => {
              // navigate("/contact")
              openModal()
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Contact Page From Home Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "PLANZ FIRST LANDING PAGE",
              })
            }}
          >
            무료 도입 문의
          </button>
        </div>
      </div>
    </Section>
  )
}

const frontground = css`
  @media (max-width: ${containerWidth}) {
    height: 100vh;
  }
  position: absolute;
  width: 100%;
  height: calc(1077px - 59px);
  background-color: #3e3e3e;
  opacity: 0.24;
  z-index: 10;
`

const backgroundImg = css`
  @media (max-width: ${containerWidth}) {
    background-image: url(${background});
    height: 100vh;
  }
  @media (max-width: 500px) {
    background-image: url(${backgroundMobile});
  }
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(1077px - 59px);
  display: flex;
  justify-content: center;
  font-family: Spoqa Han Sans Neo;
  overflow: hidden;
`
const innerContainer = css`
  width: ${containerWidth};
  @media (max-width: ${containerWidth}) {
    width: 100%;
    margin-left: 24px;
    margin-top: 107px;
    justify-content: flex-start;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 294px;
  z-index: 20;
`
const buttonsLayout = css`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const buttonContact = css`
  @media (max-width: ${containerWidth}) {
    width: 59%;
    height: 8%;
    max-height: 48px;
    font-size: 1rem;
    border-radius: 0.8rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8%;
  }

  width: 381px;
  height: 87px;
  border-radius: 20px;
  border: none;
  background-color: #4fbff0;
  font-size: 29px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: white;
  font-family: Spoqa Han Sans Neo;
`

const sub__title = css`
  @media (max-width: ${containerWidth}) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  margin-bottom: 26px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`

const title = css`
  @media (max-width: ${containerWidth}) {
    font-size: 1.6rem;
    width: 80%;
  }
  margin-bottom: 29px;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`

const welcome__title = css`
  @media (max-width: ${containerWidth}) {
    font-size: 1rem;
  }
  margin-bottom: 26px;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`

const welcome__title__custom = css`
  color: red;
`

const backgroundGrad = css`
  @media (max-width: ${containerWidth}) {
    margin-top: 50px;
    height: 400px;
    display: flex;
  }

  width: 100%;
  height: calc(677px - 59px);
  background: linear-gradient(
    to bottom right,
    rgba(250, 167, 127, 0.05),
    rgba(250, 167, 127, 0.3)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Spoqa Han Sans Neo;
  color: black;
`
const innerContainer_ = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 294px;
  z-index: 20;
`
const title_ = css`
  @media (max-width: ${containerWidth}) {
    font-size: 1.9rem;
    width: 80%;
    margin-bottom: 1.2rem;
    text-align: center;
  }
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: black;
`

const welcome__title_ = css`
  @media (max-width: ${containerWidth}) {
    font-size: 1rem;
  }

  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: black;
`
const buttonsLayout_ = css`
  @media (max-width: ${containerWidth}) {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`

const buttonContact_ = css`
  @media (max-width: ${containerWidth}) {
    width: 200px;
    height: 40px;
    font-size: 18px;
  }

  width: 281px;
  height: 57px;
  border-radius: 10px;
  border: none;
  background: #eda663;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: white;
  font-family: Spoqa Han Sans Neo;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s; /* 부드러운 효과를 위해 추가 */

  /* 호버 상태 */
  &:hover {
    background: #d89a4a; /* 호버 시 배경색 변경 */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* 호버 시 그림자 강화 */
  }
`

export default Introduction_
