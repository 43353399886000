// external modules
import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { css, keyframes } from "@emotion/core"

// internal modules
import symbol from "../../images/close.png"

// constants
import { containerWidth } from "../../constants/css.json"

type modalProps = {
  isOpen: boolean
  isFirst: boolean
  closeModal: () => {}
}

function Modal({ isOpen, isFirst, closeModal }: modalProps) {
  const onClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    location: string
  ) => {
    e.preventDefault()
    closeModal()
    navigate(location)
  }
  return (
    <div css={container(isOpen, isFirst)}>
      <ul css={links(isOpen)}>
        <li
          css={linkStyle(0)}
          onClick={e => {
            onClick(e, "/")
          }}
        >
          {"HOME"}
        </li>

        <li
          css={linkStyle(1)}
          onClick={e => {
            onClick(e, "/story")
          }}
        >
          {"OUR STORY"}
        </li>

        <li
          css={linkStyle(2)}
          onClick={e => {
            onClick(e, "/contact")
          }}
        >
          {"CONTACT"}
        </li>
      </ul>
    </div>
  )
}
const scrollDown = keyframes`
  from {
    height: 0px;
  }
  to {
    height: 140px;
  }
`
const scrollUp = keyframes`
  from {
    height: 140px;
  }
  to {
    height: 0px;
  }
`

const fadeIn = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
`

const container = (isOpen: boolean, isFirst: boolean) => {
  return css`
    @media (min-width: ${containerWidth}) {
      display: none;
    }
    visibility: ${isFirst ? "hidden" : "visible"};
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 59px;
    height: "140px";
    background-color: #ffffff;
    font-family: "Open Sans Reg";
    z-index: 1;
    animation: ${isOpen ? scrollDown : scrollUp} 0.5s ease-in-out forwards;
    overflow: hidden;
  `
}

const links = (isOpen: boolean) => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    animation: ${isOpen ? fadeIn : fadeOut} 0.5s ease-in-out forwards;
    transform-origin: top center;
  `
}

const linkStyle = (num: number) => {
  return css`
    display: block;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    color: black;
    font-size: 14px;
  `
}

export default Modal
