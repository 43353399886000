// 외부 모듈
import React from "react"
import { css } from "@emotion/core"

// image
import background from "../../images/tryFreeTastingBackground.png"
import menuImg from "../../images/menu_screen.png"
import { langTable } from "../../constants/language"

// 컴포넌트의  레이아웃이 깨지는 시점
// 각 컴포넌트 별로 다릅니다. (급해서...)
const targetWidth = "1450px"
const MOBILE_WIDTH = "500px"

interface TryFreeTastingProps {
  openModal: () => void
  langIndex: number
}

function TryFreeTasting({ openModal, langIndex }: TryFreeTastingProps) {
  return (
    <section css={container}>
      <img src={menuImg} css={menu_screen} />
      <p css={imageCaption}>* 위 이미지는 실제 플랜즈커피 메뉴 이미지입니다.</p>

      <article css={textBox}>
        <p css={freeTastingBoldText}>{langTable[langIndex].try__title}</p>
        <p css={freeTastingText}>
          본사 직원이 직접 방문하여 무료 시음 서비스를 제공합니다.
          <br />
          플랜즈 커피의 다양한 음료를 무료로 시음해보세요 😊
        </p>

        <button css={button} onClick={openModal}>
          <p css={buttonText}>{langTable[langIndex].try__button}</p>
        </button>
      </article>
    </section>
  )
}

const container = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    height: 444px;
    padding-top: 120px;
    align-items: center;
  }
  width: 100%;
  background-size: cover;
  // height: 1200px;
  background-image: url(${background});
  color: #fff;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const imageCaption = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    font-size: 0.6rem;
  }
  font-weight: 300;
  font-size: 0.8rem;
`

const textBox = css`
  margin-top: 40px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 0 auto;
    margin-top: 30px;
  }
  @media (max-width: ${targetWidth}) and (min-width: ${"501px"}) {
    margin: 206px auto 0 auto;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`

const freeTastingBoldText = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    font-size: 28px;
  }
  font-size: 50px;
  font-weight: bold;
`
const freeTastingText = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    font-size: 0.9rem;
  }
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 1.8rem;
`

const button = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    height: 65px;
    /* height: 65px; */
    margin: 4rem auto;
    width: 100%;
  }

  @media (max-width: ${targetWidth}) {
    max-width: 95vw;
  }

  width: 560px;
  height: 96px;
  margin-top: 4rem;
  border-radius: 45px;
  backdrop-filter: blur(30px);
  border: solid 4px #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  outline: none;

  /* 그림자 효과 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 기본 그림자 */

  /* 호버 상태 */
  &:hover {
    background-color: rgba(255, 255, 255, 0.3); /* 호버 시 배경색 변경 */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* 호버 시 그림자 강화 */
  }
`

const buttonText = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    font-size: 18px;
  }
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
`

const menu_screen = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    width: 85%;
    border-radius: 10px;
  }
  width: 60%;
  border-radius: 25px;
  display: flex;
  align-self: center;
`

const menuImgStyle = css`
  @media (max-width: ${targetWidth}) {
    display: none;
  }
  // width: 654.1px;
  // height: 496px;
  background-image: url(${menuImg});
  background-size: cover;
  margin-top: 81px;
`

export default TryFreeTasting
