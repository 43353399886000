import React, { useEffect, useRef, useState } from "react"
import { css, keyframes } from "@emotion/core"

// image
import newsIcon from "../../images/newsIcon.png"
import newsImg from "../../images/mou.jpeg"
import CategoryWithIcon from "./categoryWithIcon"
import useIntersection from "../../hook/useIntersection"
import { langTable } from "../../constants/language"

const LINK = "https://www.xisnd.com/article/noticeDetail/43/50/636"

// 컴포넌트 반응형 넓이
const MOBILE_WIDTH = "1200px"

function News({ langIndex }: { langIndex: number }) {
  return (
    <article css={containerStyle}>
      <div css={leftStyle}>
        <hgroup css={titleStyle} {...useIntersection()}>
          <CategoryWithIcon
            src={newsIcon}
            category={langTable[langIndex].news__title__category}
          />
          <div css={titleGroupStyle}>
            <h1 css={boldTextStyle}>플랜즈커피 & 자이에스앤디</h1>
            <h1 css={boldTextStyle}>무인 플랫폼 도입으로 발상을 전환</h1>
          </div>
        </hgroup>
        <div css={contentsTextGroupStyle} {...useIntersection()}>
          <p css={contentsTextStyle}>
            유인 운영이 불가한 입주민 복리시설 공간에 주민 간 커뮤니티 활성화
            기능 강화를 예상
          </p>
        </div>
        <a
          href={LINK}
          css={readMoreStyle}
          target="_blank"
          {...useIntersection({ distance: "80px" })}
        >
          {langTable[langIndex].news__link} &#62;
        </a>
      </div>
      <div css={rightStyle}>
        <img src={newsImg} alt="기사사진" css={newsImgStyle} />
      </div>
    </article>
  )
}

const slide = keyframes`
  from {
    transform: translate3d(-100%,0,0);
    opacity: 0;
  }
  to {
    transform: translate3d(0,0,0); 
    opacity: 1; 
  }
`

const animationStyle = css`
  animation: ${slide} 1.5s ease-in-out;
`

const containerStyle = css`
  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  border-radius: 50px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  width: 1044px;
  height: 600px;
  margin-top: 60px;
  font-family: Spoqa Han Sans Neo;
  overflow: hidden;
`
const leftStyle = css`
  width: 55%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  align-items: start;
`

const titleStyle = css`
  margin-top: 90px;
`

const titleGroupStyle = css`
  margin-top: 23px;
`
const boldTextStyle = css`
  font-size: 36px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
`

const contentsTextGroupStyle = css`
  margin-top: 37px;
`

const contentsTextStyle = css`
  width: 85%;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  word-break: keep-all;
`

const readMoreStyle = css`
  display: inline-block;
  margin-top: 20px;
  font-size: 24px;
  color: #0669be;
`

const rightStyle = css`
  width: 45%;
  height: 100%;
  flex: 1;
  overflow: hidden;
`

const newsImgStyle = css`
  height: 100%;
  margin: auto;
  /* left: -50%; */
  transform: translateX(-25%);
`

export default News
