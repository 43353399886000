// 외부모듈
import React, { ReactNode } from "react"
import { css, keyframes } from "@emotion/core"

// 내부모듈
import { zIndexList } from "../../constants/css.json"

interface ModalBackgroundProps {
  children: ReactNode
  closeModal: () => void
}

function ModalBackground({ children, closeModal }: ModalBackgroundProps) {
  return (
    <div css={backgroundContainer}>
      <div onClick={closeModal} css={backgroundStyle}></div>
      {children}
    </div>
  )
}

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.79;
  }
`

const backgroundContainer = css`
  animation: ${fadeInAnimation} 0.3s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 29;
`

const backgroundStyle = css`
  height: 100%;
  width: 100%;
  opacity: 0.79;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: #403531;
  z-index: 1;
`

export default ModalBackground
