// external modules
import React from "react"
import { css } from "@emotion/core"
import Section from "./section"
import beta from "../../images/beta_rendering.png"
import { containerWidth } from "../../constants/css.json"

function Product() {
  return (
    <Section backgroundColor="#FFFFFF" width="100%">
      <div css={product__main}>
        <div css={product__detail}>
          <div>
            <h2>무료 설치로 최고급 커피를 누리세요</h2>
            <p>
              도입 비용 0원!
              <br />
              직원들은 저렴한 가격에 최고급 커피를 즐길 수 있습니다.
            </p>
          </div>
          <div>
            <h2>최신 기술로 항상 새롭게</h2>
            <p>
              시즌별 새로운 메뉴와 핫한 메뉴들로 항상 리뉴얼!
              <br />
              구성원 정보 간편 연동으로 멤버십 기능도 쉽게 도입하세요.
            </p>
          </div>
          <div>
            <h2>무인 운영으로 깔끔하고 편리하게</h2>
            <p>
              별도의 관리 인력 없이도 깨끗하고 편리하게 운영하세요.
              <br />
              유지보수와 관리는 플랜즈가 책임지겠습니다.
            </p>
          </div>
          <div>
            <h2>직원 만족도와 생산성 UP</h2>
            <p>
              사내 카페로 직원 만족도와 생산성을 극대화하세요.
              <br />
              커피 한 잔으로 업무 스트레스를 줄이고, 팀워크를 강화하세요.
            </p>
          </div>
        </div>
        <div css={product__image_box}>
          <img
            css={product__image}
            src={beta}
            alt="Product"
            // style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </Section>
  )
}

const product__main = css`
  @media (max-width: ${containerWidth}) {
    flex-direction: column;
    padding-top: 80px;
    height: 740px;
    background: linear-gradient(
      to bottom right,
      rgba(250, 167, 127, 0.05),
      rgba(250, 167, 127, 0.3)
    );
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  width: 100%;
  height: 600px;
  background: linear-gradient(
    to top right,
    rgba(250, 167, 127, 0.05),
    rgba(250, 167, 127, 0.3)
  );
`

const product__detail = css`
  @media (max-width: ${containerWidth}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  h2 {
    @media (max-width: ${containerWidth}) {
      font-size: 1.2rem;
    }
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    @media (max-width: ${containerWidth}) {
      font-size: 0.8rem;
    }
    line-height: 1.3;
    font-size: 18px;
    font-weight: 300;
  }
  span {
    font-weight: 500;
  }
`

const product__image_box = css`
  @media (max-width: ${containerWidth}) {
    width: 90%;
    height: 100%;
  }
  position: relative;
  height: 100%;
  width: 800px;
`
const product__image = css`
  @media (max-width: ${containerWidth}) {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  width: 100%;
  position: absolute;
  bottom: 0px;
`

export default Product
