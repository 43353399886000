// external modules
import React, { ReactNode } from "react"

// interanl modules
import { css, keyframes } from "@emotion/core"
import Section from "./section"
import { containerWidth } from "../../constants/css.json"

// internal modules
import consumer_needs_jap from "../../images/consumer_needs_jap.png"
import consumer_needs2 from "../../images/consumer_needs2.png"
import needsFace1 from "../../images/needsFace1.png"
import needsFace2 from "../../images/needsFace2.png"
import needsFace3 from "../../images/needsFace3.png"
import blueCheck from "../../images/blueCheck.png"
import useIntersection from "../../hook/useIntersection"
import { langTable } from "../../constants/language"

function Needs({ langIndex }: { langIndex: number }) {
  return (
    <Section backgroundColor="#f5f5f5" width="100%">
      <div css={container} style={{ display: "none" }}>
        <div css={needsCard}>
          <div css={upper}>
            <img
              src={langIndex === 0 ? consumer_needs2 : consumer_needs_jap}
              alt=""
              css={needsTitle}
            />
            <div></div>
            <div></div>
            <p></p>
          </div>
          <div css={balloonLeftWrap}>
            <img src={needsFace1} alt="" css={needsFace} />
            <div {...useIntersection({ direction: "right", delay: 0.1 })}>
              <div css={balloonRectangle1}>
                <p css={consumerName}>
                  {langTable[langIndex].needs__itv1.name}
                </p>
                <p css={comment(langIndex)}>
                  {langTable[langIndex].needs__itv1.cmt1}
                  {/* <span css={emphasiscomment(langIndex)}>
                    {langTable[langIndex].needs__itv1.cmt2}
                    <br />
                    {langTable[langIndex].needs__itv1.cmt3}
                  </span>
                  {langTable[langIndex].needs__itv1.cmt4}
                  <br />
                  {langTable[langIndex].needs__itv1.cmt5} */}
                </p>
              </div>

              <div css={balloonTriangle1} />
            </div>
          </div>
          <div css={balloonRightWrap}>
            <img src={needsFace2} alt="" css={needsFace} />
            <div
              {...useIntersection({ direction: "left", delay: 0.2 })}
              css={balloonRectangle2__wrap}
            >
              <div css={balloonRectangle2}>
                <p css={consumerName}>
                  {langTable[langIndex].needs__itv2.name}
                </p>
                <p css={comment(langIndex)}>
                  {langTable[langIndex].needs__itv2.cmt1}
                  {/* <span css={emphasiscomment(langIndex)}>
                    {langTable[langIndex].needs__itv2.cmt2}
                  </span>
                  {langTable[langIndex].needs__itv2.cmt3}
                  <br />
                  {langTable[langIndex].needs__itv2.cmt4}
                  <br />
                  {langTable[langIndex].needs__itv2.cmt5} */}
                </p>
              </div>
              <div css={balloonTriangle2}></div>
            </div>
          </div>
          <div css={balloonLeftWrap}>
            <img src={needsFace3} alt="" css={needsFace} />
            <div {...useIntersection({ direction: "right", delay: 0.3 })}>
              <div css={balloonRectangle3}>
                <p css={consumerName}>
                  {langTable[langIndex].needs__itv3.name}
                </p>
                <p css={comment(langIndex)}>
                  {langTable[langIndex].needs__itv3.cmt1}
                  <br css={displayNoneTarget} />
                  {langTable[langIndex].needs__itv3.cmt2}{" "}
                  <span css={emphasiscomment(langIndex)}>
                    {langTable[langIndex].needs__itv3.cmt3}
                  </span>
                  {langTable[langIndex].needs__itv3.cmt4}
                  <br css={displayNoneTarget} />
                  {langTable[langIndex].needs__itv3.cmt5}
                </p>
              </div>
              <div css={balloonTriangle3}></div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

const container = css`
  @media (max-width: ${containerWidth}) {
  }
  @media (max-width: 520px) {
  }
  width: 100%;
  height: 780px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Spoqa Han Sans Neo;
`

const needsCard = css`
  @media (max-width: ${containerWidth}) {
    width: 480px;
    padding-bottom: 50px;
    /* height: 800px; */
  }
  @media (max-width: 520px) {
    width: 100%;
    border-radius: 0px;
  }
  margin-top: 60px;
  background-color: #ffffff;
  width: 1044px;
  height: 736px;
  /* margin-top: 60px; */
  border-radius: 45px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
`

const upper = css`
  @media (max-width: ${containerWidth}) {
    margin-bottom: 36px;
  }
  @media (max-width: 340px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  width: 100%;
  margin-top: 58px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const needsTitle = css`
  @media (max-width: ${containerWidth}) {
    width: 406px;
  }
  @media (max-width: 500px) {
    width: 330px;
  }

  width: 526px;
  height: 97px;
  object-fit: contain;
`

const balloonLeftWrap = css`
  @media (max-width: ${containerWidth}) {
    width: 100%;
    margin: 0px auto 0px 0px;
  }

  position: relative;
  width: 100%;
  height: 140px;
  margin: 0px auto 0px 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 17px;
`

const balloonRightWrap = css`
  @media (max-width: ${containerWidth}) {
    width: 98%;
    margin: 24px 0px 24px auto;
  }
  @media (max-width: 370px) {
    width: 94%;
    margin: 30px 0px 34px auto;
  }
  @media (max-width: 340px) {
    width: 94%;
    margin: 40px 0px 42px auto;
  }

  position: relative;
  width: 100%;
  height: 140px;
  margin: 17px auto 17px -72px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`
const balloonRectangle1 = css`
  @media (max-width: 500px) {
    width: 70%;
  }
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 298px;
  padding: 18px 18px;
  margin-left: 5px;
  border-radius: 20px;
  background-color: #ebebeb;
`
const balloonTriangle1 = css`
  @media (max-width: ${containerWidth}) {
    left: -10px;
    border-top: 24px solid #ebebeb;
  }
  position: absolute;
  bottom: 26px;
  left: -10px;
  width: 0px;
  height: 0px;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 30px solid #ebebeb;
  transform: rotate(90deg);
  z-index: -1;
`

const balloonRectangle2__wrap = css`
  @media (max-width: 500px) {
    right: 120px;
  }
  position: absolute;
  right: 140px;
`

const balloonRectangle2 = css`
  @media (max-width: 500px) {
    width: 70.5%;
  }

  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 278px;
  padding: 18px 18px;
  border-radius: 20px;
  background-color: #faf0e8;
`
const balloonTriangle2 = css`
  @media (max-width: ${containerWidth}) {
    right: -16px;
    border-top: 24px solid #faf0e8;
  }
  @media (max-width: 500px) {
    display: none;
  }
  @media (max-width: 320px) {
    /* border-top: 24px solid #faf0e8; */
  }
  position: absolute;
  bottom: 26px;
  right: -12px;
  /* color: red; */
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 30px solid #faf0e8;
  transform: rotate(-90deg);
  z-index: 11;
`
const balloonRectangle3 = css`
  @media (max-width: ${containerWidth}) {
    width: 85%;
    margin-top: 15px;
    padding-right: 0px;
  }
  @media (max-width: 390px) {
    height: 140px;
    width: 80%;
  }
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 340px;
  padding: 18px 18px;
  margin-left: 5px;
  border-radius: 20px;
  background-color: #ebebeb;
`
const balloonTriangle3 = css`
  @media (max-width: ${containerWidth}) {
    left: -10px;
    border-top: 24px solid #ebebeb;
  }
  position: absolute;
  bottom: 26px;
  left: -10px;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 30px solid #ebebeb;
  transform: rotate(90deg);
  z-index: -1;
`

const needsFace = css`
  @media (max-width: ${containerWidth}) {
    width: 126px;
    height: 126px;
  }
  width: 136px;
  height: 136px;
  object-fit: contain;
  line-height: 1.21;
`
const consumerName = css`
  @media (max-width: 520px) {
    font-size: 14px;
  }
  @media (max-width: 340px) {
    font-size: 13px;
  }
  font-size: 19px;
  font-weight: medium;
  line-height: 1.1;
  color: #414141;
`
const comment = (langIndex: number) => css`
  @media (max-width: 490px) {
    font-size: ${langIndex === 1 ? `11px` : `14px`};
  }
  @media (max-width: 340px) {
    font-size: ${langIndex === 1 ? `8px` : `12px`};
  }

  font-size: 16px;
  font-weight: medium;
  line-height: 1.5;
  color: #414141;
`
const emphasiscomment = (langIndex: number) => css`
  @media (max-width: 490px) {
    font-size: ${langIndex === 1 ? `11px` : `14px`};
    font-weight: bold;
  }
  @media (max-width: 340px) {
    font-size: ${langIndex === 1 ? `8px` : `12px`};
  }
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
`

const displayNoneTarget = css`
  @media (max-width: ${containerWidth}) {
    display: none;
  }
`
export default Needs
