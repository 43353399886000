// external modules
import React, { ReactNode } from "react"

// interanl modules
import { css } from "@emotion/core"
import { containerWidth } from "../../constants/css.json"

// type annotation
type SectionProps = {
  backgroundColor: string
  width?: string
  children: ReactNode
}

// Section 컴포넌트는 각 화면의 구획을 표현합니다.
function Section({ backgroundColor, width, children }: SectionProps) {
  return <div css={container({ backgroundColor, width })}>{children}</div>
}
interface containerParamType {
  backgroundColor: string
  width?: string
}
const container = ({ backgroundColor, width }: containerParamType) => css`
  width: ${width ? width : containerWidth};
  background-color: ${backgroundColor};
  font-family: Spoqa Han Sans;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Section
