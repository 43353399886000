// 외부모듈
import React, { useEffect, useRef } from "react"
import { css, keyframes } from "@emotion/core"

import { langTable } from "../../constants/language"

// images
import menuIconList from "../../images/menuIconList.png"
import menuIcon1 from "../../images/menu1.png"
import menuIcon2 from "../../images/menu2.png"
import menuIcon3 from "../../images/menu3.png"
import menuIcon4 from "../../images/menu4.png"
import menuIcon5 from "../../images/menu5.png"
import menuIcon6 from "../../images/menu6.png"
import menuIcon7 from "../../images/menu7.png"
import menuIcon8 from "../../images/menu8.png"

// 컴포넌트 반응형 넓이
const MOBILE_WIDTH = "1200px"

function throttle(callback: any, limit = 10) {
  let waiting = false
  return function () {
    if (!waiting) {
      // @ts-ignore
      callback.apply(this, arguments)
      waiting = true
      setTimeout(() => {
        waiting = false
      }, limit)
    }
  }
}

function Menu({ langIndex }: { langIndex: number }) {
  useEffect(() => {
    const root = document.querySelector(".menuContainer") as HTMLElement
    const el = document.querySelector(".imgList") as HTMLElement

    window.addEventListener(
      "scroll",
      throttle((e: any) => {
        const offsetTop = root.getBoundingClientRect().top
        const scrollTop = e.target.scrollingElement.scrollTop
        const clientHeight = e.target.scrollingElement.clientHeight
        const curScrollHeight =
          window.pageYOffset + offsetTop - scrollTop - clientHeight

        if (curScrollHeight > 350 || curScrollHeight < -1500) return
        el.style.transform = `translateX(${curScrollHeight / 2}px)`
      })
    )
  }, [])

  return (
    <section css={containerStyle} className="menuContainer">
      <div css={topStyle} className="imgList">
        <img src={menuIcon1} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon2} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon3} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon4} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon5} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon6} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon7} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon8} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon1} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon2} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon3} css={[menuIconListStyle]} alt="메뉴아이콘들" />
        <img src={menuIcon4} css={[menuIconListStyle]} alt="메뉴아이콘들" />
      </div>
      <div css={bottomStyle}>
        <hgroup>
          <h1 css={titleStyle}>{langTable[langIndex].menu__title__upper}</h1>
          <h1 css={titleStyle}>{langTable[langIndex].menu__title__lower}</h1>
        </hgroup>
        <p css={contentsTextStyle}>{langTable[langIndex].menu__content}</p>
      </div>
    </section>
  )
}

const containerStyle = css`
  @media all and (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1044px;
  height: 560px;
  border-radius: 50px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
  background-image: linear-gradient(89deg, #242424 0%, #292929 100%);
  overflow: hidden;
  margin-top: 60px;
  font-family: Spoqa Han Sans Neo;
`

const topStyle = css`
  margin-top: 50px;
  display: flex;
`
const menuIconListStyle = css`
  width: 180px;
`
const bottomStyle = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 9.8%;
  padding: 71px 0.5vw 0 0;
`
const titleStyle = css`
  font-size: 38px;
  font-weight: 600;
  line-height: 1.5;
  color: #fff;
  width: 289px;
`
const contentsTextStyle = css`
  font-size: 22px;
  font-weight: normal;
  line-height: 1.5;
  color: #fff;
  width: 360px;
`

export default Menu
