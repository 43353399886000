/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// external modules
import React, { useState } from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"

// internal modules
import Header from "./header"
import "./layout.css"
import Footer from "./Footer"
import Modal from "./modal"

const Layout = ({ children, currentPath, setLangIndex, langIndex }) => {
  const [isOpen, setModal] = useState(false)
  const [isFirst, setIsFirst] = useState(true)

  return (
    <>
      <Global
        styles={css`
          div {
          }
        `}
      />
      <Header
        currentPath={currentPath}
        isOpen={isOpen}
        toggleModal={() => {
          setModal(!isOpen)
          setIsFirst(false)
        }}
      />
      <Modal
        isOpen={isOpen}
        isFirst={isFirst}
        closeModal={() => {
          setModal(false)
        }}
      />
      <div>
        <main css={main}>{children}</main>
        <Footer setLangIndex={setLangIndex} langIndex={langIndex} />
      </div>
    </>
  )
}

const main = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f5;
`
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
