// external modules
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { css, keyframes } from "@emotion/core"
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "react-spring"

// interanl modules\
import Section from "./section"
import { containerWidth } from "../../constants/css.json"

// internal modules
import arrow from "../../images/arrow.png"
import case_1 from "../../images/case_dooSan.png"
import case_2 from "../../images/case_ff.jpg"
import case_3 from "../../images/case_comico.jpeg"
import case_4 from "../../images/case_tesna.png"
import { langTable } from "../../constants/language"

// Section 컴포넌트는 각 화면의 구획을 표현합니다.
function Case({ langIndex }: { langIndex: number }) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [count, setCount] = useState(5)

  const caseList = [
    {
      Title: langTable[langIndex].case__list[0].title,
      SubTitle: langTable[langIndex].case__list[0].subTitle,
      Name: langTable[langIndex].case__list[0].name,
      Comment: langTable[langIndex].case__list[0].comment,
      img: case_4,
    },
    {
      Title: langTable[langIndex].case__list[1].title,
      SubTitle: langTable[langIndex].case__list[1].subTitle,
      Name: langTable[langIndex].case__list[1].name,
      Comment: langTable[langIndex].case__list[1].comment,
      img: case_1,
    },
    {
      Title: langTable[langIndex].case__list[2].title,
      SubTitle: langTable[langIndex].case__list[2].subTitle,
      Name: langTable[langIndex].case__list[2].name,
      Comment: langTable[langIndex].case__list[2].comment,
      img: case_2,
    },
    {
      Title: langTable[langIndex].case__list[3].title,
      SubTitle: langTable[langIndex].case__list[3].subTitle,
      Name: langTable[langIndex].case__list[3].name,
      Comment: langTable[langIndex].case__list[3].comment,
      img: case_3,
    },
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count => count - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (count < 0) {
      setSelectedIndex((selectedIndex + 1) % caseList.length)
      setCount(5)
    }
  }, [count])

  const prevImgIndex = useRef(0)
  const nextImgIndex = (selectedIndex + 1) % caseList.length

  useEffect(() => {
    prevImgIndex.current = selectedIndex
  }, [selectedIndex])

  const backgroundStyle = (idx: number) =>
    useSpring({
      opacity: idx === selectedIndex ? 1 : 0,
      config: { duration: 500 },
    })

  const transition = useTransition(selectedIndex, {
    from: { opacity: 0, transform: "translate3d(-50%, 100px,0)" },
    enter: { opacity: 1, transform: "translate3d(-50%, 0px,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%, 100px,0)" },
  })

  return (
    <Section backgroundColor="#787878" width="100%">
      <div css={container(caseList[selectedIndex].img)}>
        {caseList.map(({ img }, idx) => {
          return (
            <animated.img
              key={idx}
              src={img}
              alt=""
              css={backgroundImg(idx, selectedIndex, prevImgIndex.current)}
              style={backgroundStyle(idx)}
            />
          )
        })}

        <pre css={upper}>{caseList[selectedIndex].Title}</pre>
        <div css={caseWrap}>
          <div css={caseLeftBox}>
            {transition((style, selectedIndex) => {
              return (
                <animated.div css={caseTitleBox} style={style}>
                  <pre css={caseTitle}>{caseList[selectedIndex].Title}</pre>
                  <p css={caseSubTitle}>{caseList[selectedIndex].SubTitle}</p>
                </animated.div>
              )
            })}
          </div>
          <div css={caseRightBox}>
            <div css={caseNameBox}>
              <div
                css={leftArrow}
                onClick={() => {
                  selectedIndex > 0
                    ? setSelectedIndex((selectedIndex - 1) % caseList.length)
                    : setSelectedIndex(3)
                  setCount(7)
                }}
              >
                &lt;
              </div>
              <div css={caseName}> {caseList[selectedIndex].Name}</div>
              <div
                css={rightArrow}
                onClick={() => {
                  setSelectedIndex((selectedIndex + 1) % caseList.length)
                  setCount(7)
                }}
              >
                &gt;
              </div>
            </div>
            <div css={caseCommentBox}>
              {transition((style, selectedIndex) => {
                return (
                  <animated.p css={caseCommentCur} style={style}>
                    {caseList[selectedIndex].Comment}
                  </animated.p>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

const container = (img: any) => css`
  @media (max-width: ${containerWidth}) {
    /* width: 100vw; */
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  position: relative;
  width: 100%;
  height: 1080px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  font-family: Spoqa Han Sans Neo;
`

const backgroundImg = (
  idx: number,
  selectedIndex: number,
  prevImgIndex: number
) => css`
  /* display: none; */
  @media (max-width: ${containerWidth}) {
    /* width: 100vw; */
    height: 100vh;
  }
  position: absolute;
  display: ${idx === selectedIndex || idx === prevImgIndex ? `block` : `none`};
  opacity: ${idx === selectedIndex ? 1 : idx === prevImgIndex && 0};
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1080px;
  object-fit: cover;
`
const backgroundImg2 = css`
  position: absolute;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1080px;
  object-fit: cover;
`
const backgroundImg3 = css`
  position: absolute;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1080px;
  object-fit: cover;
`
const backgroundImg4 = css`
  position: absolute;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1080px;
  object-fit: cover;
`

const upper = css`
  @media (max-width: ${containerWidth}) {
    display: block;
    padding: 0.5rem;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(32.4px);
    backdrop-filter: blur(32.4px) contrast(150%) brightness(70%);
    font-size: 1.6rem;
  }
  @media (max-width: 360px) {
    display: block;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(32.4px);
    backdrop-filter: blur(32.4px) contrast(150%) brightness(70%);
  }
  display: none;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #fff;
`

const caseWrap = css`
  @media (max-width: ${containerWidth}) {
    width: 90%;
  }
  @media (max-width: 500px) {
    height: 22%;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const caseLeftBox = css`
  @media (max-width: ${containerWidth}) {
    display: none;
  }
  position: relative;
  width: 636px;
  height: 324px;
  margin-right: 78px;
  /* border: 4px solid #fff; */
  border-radius: 40px;
  -webkit-backdrop-filter: blur(32.4px);
  backdrop-filter: blur(32.4px) contrast(150%) brightness(30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const caseTitleBox = css`
  position: absolute;
  // top: 0;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

const caseTitle = css`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #fff;
`

const caseSubTitle = css`
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  width: 80%;
  word-break: keep-all;
`

const caseRightBox = css`
  @media (max-width: 500px) {
    width: 70%;
    height: 100%;
  }
  width: 330px;
  height: 324px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`
const caseNameBox = css`
  @media (max-width: ${containerWidth}) {
    width: 100%;
    height: 58px;
  }
  @media (max-width: 500px) {
    backdrop-filter: blur(32.4px) contrast(80%) brightness(70%);
    height: 40%;
    font-size: 1.5rem;
    border-radius: 10px;
  }
  width: 100%;
  height: 89px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-bottom: 22px;
  /* border: 4px solid #fff; */
  border-radius: 40px;
  -webkit-backdrop-filter: blur(32.4px) contrast(80%);
  backdrop-filter: blur(32.4px) contrast(40%) brightness(30%);
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
`
const caseName = css`
  @media (max-width: ${containerWidth}) {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 1rem;
    font-weight: normal;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
    width: 65%;
  }
  width: 210px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const leftArrow = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    cursor: pointer;
  }
  font-weight: bold;
`

const rightArrow = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    cursor: pointer;
  }
  font-weight: bold;
`

const caseCommentBox = css`
  @media (max-width: ${containerWidth}) {
    width: 100%;

    backdrop-filter: blur(32.4px) contrast(80%) brightness(70%);
    /* padding: 1rem; */
  }
  @media (max-width: 500px) {
    height: 70%;

    border-radius: 1rem;
  }
  display: flex;
  width: 100%;
  height: 213px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 4px solid #fff; */
  border-radius: 40px;
  -webkit-backdrop-filter: blur(32.4px);
  backdrop-filter: blur(32.4px) contrast(80%) brightness(30%);
`

const caseCommentCur = css`
  @media (max-width: ${containerWidth}) {
    width: 85%;
  }
  @media (max-width: 500px) {
    height: auto;
    width: 85%;
    font-size: 1rem;
    align-items: center;
    text-align: center;
  }
  position: absolute;
  width: 90%;
  left: 50%;

  text-align: center;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  word-break: keep-all;
  /* opacity: 0; */
`

export default Case
